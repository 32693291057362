var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", flat: "" } },
    [
      _c(
        "v-row",
        { staticClass: "pt-3" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { dark: "", flat: "", color: "#424242" } },
                [
                  _c(
                    "span",
                    { staticClass: "text-subtitle-1 d-flex justify-center" },
                    [_vm._v(_vm._s(_vm.headerTitle) + "を選ぶ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { "max-width": "200" } },
                [_c("v-img", { attrs: { src: _vm.imageSource } })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": "",
                    transition: "scale-transition",
                    "offset-y": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    label: _vm.headerTitle,
                                    outlined: "",
                                    readonly: "",
                                    "append-icon": "mdi-menu-down",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.model,
                                    callback: function ($$v) {
                                      _vm.model = $$v
                                    },
                                    expression: "model",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "ma-0 pa-0",
                      staticStyle: { "max-height": "200px" },
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-list-item-group",
                            _vm._l(_vm.inputItemsSorted, function (item) {
                              return _c(
                                "v-list-item",
                                {
                                  key: item.val,
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitItem(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(" " + _vm._s(item.val) + " "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }